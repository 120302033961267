<template>
  <div class="liveDetails">
    <div class="pla-cont-header">
      <span>统计时间段：{{timearea}} </span>
    </div>
    <div class="table_sList">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="600"
        align="center"
        row-key='id'>
        <el-table-column
          label="序号"
          align="center"
          width="55"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.prop"
          :label="item.label ? item.label : '/'"
          :width="item.width"
          align="center"
          :sortable="item.prop != 'tenantName' ? true : false"
        >
          <template slot-scope="scope">
            <span v-if="scope.row[scope.column.property] == null">/</span>
            <template v-else-if="scope.column.property == 'tenantName'">
              <el-tooltip
                :content="scope.row[scope.column.property]"
                placement="top"
                effect="light"
                popper-class="pubToop"
                v-if="scope.row[scope.column.property] != 'total'"
              >
                <div class="ellipsis">{{ scope.row[scope.column.property] }}</div>
              </el-tooltip>
              <span v-else>总计</span>
            </template>
            <span v-else-if="scope.column.property == 'liveTotalTime' || scope.column.property == 'liveWatchAvgTime' || scope.column.property == 'playbackWatchTime'">{{$way.toHHmmss(scope.row[scope.column.property]*60000)}}</span>
            <span v-else-if="scope.column.property == 'participantRate' || scope.column.property == 'onlineRate'">{{scope.row[scope.column.property]}}%</span>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination> -->
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'liveDetails',
  components: {
    Pagination
  },
  data() {
    return {
      formInline: {
        beginTime: null,
        endTime: null,
        timeType: null
      },
      columnList: [
        {
          prop: "tenantName",
          label: "学校名称"
        },
        {
          prop: "releaseLiveNum",
          label: "发布场次",
          width: 100
        },
        {
          prop: "releaseStudentNum",
          label: "发布人数",
          width: 100
        },
        {
          prop: "participantNum",
          label: "参与人数",
          width: 100
        },
        {
          prop: "participantRate",
          label: "参与率",
          width: 90
        },
        {
          prop: "liveTotalTime",
          label: "直播总时长"
        },
        {
          prop: "liveWatchAvgTime",
          label: "学生平均观看时长"
        },
        {
          prop: "onlineRate",
          label: "在线时长占比"
        },
        {
          prop: "playbackWatchTime",
          label: "回放观看时长"
        },
        {
          prop: "commentNum",
          label: "评论次数",
          width: 100
        },
        {
          prop: "interactiveNum",
          label: "连麦次数",
          width: 100
        }
      ],
      tableData: [],
      timearea: null,
    }
  },
  methods: {
    showEvent(obj) {
      this.timearea = obj.timearea
      this.formInline.beginTime = obj.time != 'total' ? this.$way.dateReturnTimestamp(obj.time) : obj.bTime
      this.formInline.endTime = obj.time != 'total' ? null : obj.eTime
      this.formInline.timeType = obj.timeType
      this.getLiveStatisticDetail()
    },
    // 表格数据
    getLiveStatisticDetail() {
      this.$request.liveStatisticDetail(this.formInline).then(res => {
        if(res.data.code == 0) {
          let data = JSON.parse(JSON.stringify(res.data.data))
          let list = data.list
          list.unshift(data.total)
          list[0].tenantName = 'total'
          this.tableData = list
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
  .liveDetails {
    box-sizing: border-box;
    padding: 20px;
    .pla-cont-header {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      &>span:nth-of-type(2) {
        margin-left: 20px;
        display: flex;
        align-items: center;
        img {
          width: 14px;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      margin-top: 10px;
      background-color: #fff;
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-pagination {
        text-align: right;
      }
    }
  }
</style>