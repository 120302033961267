import { render, staticRenderFns } from "./tableDetails.vue?vue&type=template&id=445fe586&scoped=true"
import script from "./tableDetails.vue?vue&type=script&lang=js"
export * from "./tableDetails.vue?vue&type=script&lang=js"
import style0 from "./tableDetails.vue?vue&type=style&index=0&id=445fe586&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445fe586",
  null
  
)

export default component.exports